export default [
  {
    path: '/staffs',
    name: 'staffs',
    // eslint-disable-next-line import/no-unresolved
    component: () => import('@/views/staffs/Manage.vue'),
    meta: {
      redirectIfLoggedIn: true,
      pageTitle: 'Nhân Viên',
      breadcrumb: [
        {
          text: 'Danh sách nhân Viên',
          active: true,
        },
      ],
    },
  },
  {
    path: '/staffs/create',
    name: 'staffs-create',
    // eslint-disable-next-line import/no-unresolved
    component: () => import('@/views/staffs/Create.vue'),
    meta: {
      redirectIfLoggedIn: true,
      pageTitle: 'Nhân Viên',
      breadcrumb: [
        {
          text: 'Danh sách nhân Viên',
          to: { name: 'staffs' },
        },
        {
          text: 'Tạo thêm nhân viên',
          active: true,
        },
      ],
    },
  },
  {
    path: '/staffs/edit/:id',
    name: 'staffs-edit',
    // eslint-disable-next-line import/no-unresolved
    component: () => import('@/views/staffs/Create.vue'),
    props: true,
    meta: {
      redirectIfLoggedIn: true,
      pageTitle: 'Nhân Viên',
      breadcrumb: [
        {
          text: 'Danh sách nhân viên',
          to: { name: 'staffs' },
        },
        {
          text: 'Chỉnh sửa',
          active: true,
        },
      ],
    },
  },
  {
    path: '/groups',
    name: 'groups',
    // eslint-disable-next-line import/no-unresolved
    component: () => import('@/views/group/ManageGroup.vue'),
    meta: {
      redirectIfLoggedIn: true,
      pageTitle: 'Quản lý Nhóm',
      breadcrumb: [
        {
          text: 'Group',
          active: true,
        },
      ],
    },
  },
  {
    path: '/groups/create',
    name: 'groups-create',
    // eslint-disable-next-line import/no-unresolved
    component: () => import('@/views/group/CreateGroup.vue'),
    meta: {
      redirectIfLoggedIn: true,
      pageTitle: 'Quản Lý Nhóm',
      breadcrumb: [
        {
          text: 'Danh sách Group',
          to: { name: 'groups' },
        },
        {
          text: 'Tạo Nhóm Mới',
          active: true,
        },
      ],
    },
  },
  {
    path: '/groups/edit/:id',
    name: 'groups-edit',
    props: true,
    // eslint-disable-next-line import/no-unresolved
    component: () => import('@/views/group/CreateGroup.vue'),
    meta: {
      redirectIfLoggedIn: true,
      pageTitle: 'Quản Lý Nhóm',
      breadcrumb: [
        {
          text: 'Danh sách Nhóm',
          to: { name: 'groups' },
        },
        {
          text: 'Sửa Nhóm',
          active: true,
        },
      ],
    },
  },
  {
    path: '/roles',
    name: 'roles',
    // eslint-disable-next-line import/no-unresolved
    component: () => import('@/views/role/Manage.vue'),
    meta: {
      redirectIfLoggedIn: true,
      pageTitle: 'Quản lý Nhóm',
      breadcrumb: [
        {
          text: 'Group',
          active: true,
        },
      ],
    },
  },
  {
    path: '/roles/create',
    name: 'roles-create',
    // eslint-disable-next-line import/no-unresolved
    component: () => import('@/views/role/Create.vue'),
    meta: {
      redirectIfLoggedIn: true,
      pageTitle: 'Quản Lý Quyền',
      breadcrumb: [
        {
          text: 'Danh sách Quyền',
          to: { name: 'roles' },
        },
        {
          text: 'Thêm Quyền Mới',
          active: true,
        },
      ],
    },
  },
  {
    path: '/roles/edit/:id',
    name: 'roles-edit',
    props: true,
    // eslint-disable-next-line import/no-unresolved
    component: () => import('@/views/role/Create.vue'),
    meta: {
      redirectIfLoggedIn: true,
      pageTitle: 'Quản Lý Quyền',
      breadcrumb: [
        {
          text: 'Danh sách Quyền',
          to: { name: 'roles' },
        },
        {
          text: 'Sửa Quyền',
          active: true,
        },
      ],
    },
  },
]

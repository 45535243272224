export default [
  {
    path: '/report-revenue',
    name: 'report-revenue',
    // eslint-disable-next-line import/no-unresolved
    component: () => import('@/views/report/revenue.vue'),
    meta: {
      redirectIfLoggedIn: true,
      pageTitle: 'Quản lý Báo Cáo Doanh Số',
      breadcrumb: [
        {
          text: 'Báo Cáo Doanh Số',
          active: true,
        },
      ],
    },
    },
    {
    path: '/report-not-action',
    name: 'report-not-action',
    // eslint-disable-next-line import/no-unresolved
    component: () => import('@/views/report/notAction.vue'),
    meta: {
      redirectIfLoggedIn: true,
      pageTitle: 'Quản lý Báo Cáo Chưa Xử Lý',
      breadcrumb: [
        {
          text: 'Báo Cáo Đơn Chưa Xử Lý',
          active: true,
        },
      ],
    },
  },
  {
    path: '/report',
    name: 'report',
    // eslint-disable-next-line import/no-unresolved
    component: () => import('@/views/report/index.vue'),
    meta: {
      redirectIfLoggedIn: true,
      pageTitle: 'Quản lý Báo Cáo Doanh Số',
      breadcrumb: [
        {
          text: 'Danh Sinh',
          active: true,
        },
      ],
    },
  },
  {
    path: '/report-class',
    name: 'report-class',
    // eslint-disable-next-line import/no-unresolved
    component: () => import('@/views/report/class.vue'),
    meta: {
      redirectIfLoggedIn: true,
      pageTitle: 'Quản lý Báo Cáo Q.Lý Lớp',
      breadcrumb: [
        {
          text: 'Báo Cáo Q.Lý Lớp',
          active: true,
        },
      ],
    },
  },
  {
    path: '/report-sale',
    name: 'report-sale',
    // eslint-disable-next-line import/no-unresolved
    component: () => import('@/views/report/sale.vue'),
    meta: {
      redirectIfLoggedIn: true,
      pageTitle: 'Quản lý Báo Cáo CarePage',
      breadcrumb: [
        {
          text: 'Báo Cáo CarePage',
          active: true,
        },
      ],
    },
  },
  {
    path: '/report-marketing',
    name: 'report-marketing',
    // eslint-disable-next-line import/no-unresolved
    component: () => import('@/views/report/marketing.vue'),
    meta: {
      redirectIfLoggedIn: true,
      pageTitle: 'Quản lý Báo Cáo Marketing',
      breadcrumb: [
        {
          text: 'Báo Cáo Marketing',
          active: true,
        },
      ],
    },
  },
  {
    path: '/report-teacher',
    name: 'report-teacher',
    // eslint-disable-next-line import/no-unresolved
    component: () => import('@/views/report/teacher.vue'),
    meta: {
      redirectIfLoggedIn: true,
      pageTitle: 'Quản lý Báo Cáo Teacher',
      breadcrumb: [
        {
          text: 'Báo Cáo Teacher',
          active: true,
        },
      ],
    },
  },
  {
    path: '/report',
    name: 'report',
    // eslint-disable-next-line import/no-unresolved
    component: () => import('@/views/report/report.vue'),
    meta: {
      redirectIfLoggedIn: true,
      pageTitle: 'Quản lý Báo Cáo',
      breadcrumb: [
        {
          text: 'Báo Cáo',
          active: true,
        },
      ],
    },
  },
  {
    path: '/report-student-parent',
    name: 'report-student-parent',
    // eslint-disable-next-line import/no-unresolved
    component: () => import('@/views/report/studentParentCount.vue'),
    meta: {
      redirectIfLoggedIn: true,
      pageTitle: 'Quản lý Báo Cáo',
      breadcrumb: [
        {
          text: 'Báo Cáo',
          active: true,
        },
      ],
    },
  },
  {
    path: '/report-status',
    name: 'report-status',
    // eslint-disable-next-line import/no-unresolved
    component: () => import('@/views/report/Status.vue'),
    meta: {
      redirectIfLoggedIn: true,
      pageTitle: 'Quản lý Báo Cáo',
      breadcrumb: [
        {
          text: 'Báo Cáo Tình Trạng Sử Dụng',
          active: true,
        },
      ],
    },
  },
  {
    path: '/report-my-stat',
    name: 'report-my-stat',
    // eslint-disable-next-line import/no-unresolved
    component: () => import('@/views/report/myStat.vue'),
    meta: {
      redirectIfLoggedIn: true,
      pageTitle: 'Quản lý Báo Cáo',
      breadcrumb: [
        {
          text: 'Báo Cáo Nhân Viên Xử Lý Đơn',
          active: true,
        },
      ],
    },
  },
]

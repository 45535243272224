export default [
  {
    path: '/manager-classes',
    name: 'manager-classes',
    // eslint-disable-next-line import/no-unresolved
    component: () => import('@/views/class/class/Manage.vue'),
    meta: {
      redirectIfLoggedIn: true,
      pageTitle: 'Quản lý Lớp Học',
      breadcrumb: [
        {
          text: 'Danh sách Lớp Học',
          active: true,
        },
      ],
    },
  },
  {
    path: '/manager-classes/create',
    name: 'manager-classes-create',
    // eslint-disable-next-line import/no-unresolved
    component: () => import('@/views/class/class/Create.vue'),
    meta: {
      redirectIfLoggedIn: true,
      pageTitle: 'Lớp Học',
      breadcrumb: [
        {
          text: 'Danh sách Lớp Học',
          to: { name: 'classes' },
        },
        {
          text: 'Tạo lớp học',
          active: true,
        },
      ],
    },
  },
  {
    path: '/manager-classes/edit/:id',
    name: 'manager-classes-edit',
    props: true,
    // eslint-disable-next-line import/no-unresolved
    component: () => import('@/views/class/class/Create.vue'),
    meta: {
      redirectIfLoggedIn: true,
      pageTitle: 'Lớp Học',
      breadcrumb: [
        {
          text: 'Danh sách Lớp Học',
          to: { name: 'classes' },
        },
        {
          text: 'Sửa Lớp học',
          active: true,
        },
      ],
    },
  },
  {
    path: '/manager-teacher',
    name: 'manager-teacher',
    props: true,
    // eslint-disable-next-line import/no-unresolved
    component: () => import('@/views/class/teacher/Manage.vue'),
    meta: {
      redirectIfLoggedIn: true,
      pageTitle: 'Quản lý Giáo Viên',
      breadcrumb: [
        {
          text: 'Danh sách Giáo Viên',
          active: true,
        },
      ],
    },
  },
  {
    path: '/teacher/edit/:id',
    name: 'teacher-edit',
    props: true,
    // eslint-disable-next-line import/no-unresolved
    component: () => import('@/views/class/teacher/Create.vue'),
    meta: {
      redirectIfLoggedIn: true,
      pageTitle: 'Quản lý Giáo Viên',
      breadcrumb: [
        {
          text: 'Danh sách Giáo Viên',
          to: { name: 'teacher' },
        },
        {
          text: 'Sửa Giáo Viên',
          active: true,
        },
      ],
    },
  },
  {
    path: '/teacher/create',
    name: 'teacher-create',
    // eslint-disable-next-line import/no-unresolved
    component: () => import('@/views/class/teacher/Create.vue'),
    meta: {
      redirectIfLoggedIn: true,
      pageTitle: 'Quản lý Giáo Viên',
      breadcrumb: [
        {
          text: 'Danh sách Giáo Viên',
          to: { name: 'teacher' },
        },
        {
          text: 'Thêm Giáo Viên',
          active: true,
        },
      ],
    },
  },
  {
    path: '/manager-at',
    name: 'manager-at',
    props: true,
    // eslint-disable-next-line import/no-unresolved
    component: () => import('@/views/class/at/Manage.vue'),
    meta: {
      redirectIfLoggedIn: true,
      pageTitle: 'Quản Lý Trợ Giảng',
      breadcrumb: [
        {
          text: 'Danh sách Trợ Giảng',
          active: true,
        },
      ],
    },
  },
  {
    path: '/manager-at/edit/:id',
    name: 'manager-at-edit',
    props: true,
    // eslint-disable-next-line import/no-unresolved
    component: () => import('@/views/class/at/Create.vue'),
    meta: {
      redirectIfLoggedIn: true,
      pageTitle: 'Quản lý Trợ Giảng',
      breadcrumb: [
        {
          text: 'Danh sách Trợ Giảng',
          to: { name: 'manager-at' },
        },
        {
          text: 'Sửa Trợ Giảng',
          active: true,
        },
      ],
    },
  },
  {
    path: '/manager-at/create',
    name: 'manager-at-create',
    // eslint-disable-next-line import/no-unresolved
    component: () => import('@/views/class/at/Create.vue'),
    meta: {
      redirectIfLoggedIn: true,
      pageTitle: 'Quản Lý Trợ Giảng',
      breadcrumb: [
        {
          text: 'Danh Sách Trợ Giảng',
          to: { name: 'manager-at' },
        },
        {
          text: 'Thêm Trợ Giảng',
          active: true,
        },
      ],
    },
  },
  
  {
    path: '/manager-student',
    name: 'manager-student',
    props: true,
    // eslint-disable-next-line import/no-unresolved
    component: () => import('@/views/class/student/Manage.vue'),
    meta: {
      redirectIfLoggedIn: true,
      pageTitle: 'Quản Lý Học Viên',
      breadcrumb: [
        {
          text: 'Danh sách Học Viên',
          active: true,
        },
      ],
    },
  },
  {
    path: '/manager-student/edit/:id',
    name: 'manager-student-edit',
    props: true,
    // eslint-disable-next-line import/no-unresolved
    component: () => import('@/views/class/student/Create.vue'),
    meta: {
      redirectIfLoggedIn: true,
      pageTitle: 'Quản lý Học Viên',
      breadcrumb: [
        {
          text: 'Danh sách Học Viên',
          to: { name: 'manager-student' },
        },
        {
          text: 'Sửa Học Viên',
          active: true,
        },
      ],
    },
  },
  {
    path: '/manager-student/create',
    name: 'manager-student-create',
    // eslint-disable-next-line import/no-unresolved
    component: () => import('@/views/class/student/Create.vue'),
    meta: {
      redirectIfLoggedIn: true,
      pageTitle: 'Quản Lý Học Viên',
      breadcrumb: [
        {
          text: 'Danh Sách Học Viên',
          to: { name: 'manager-student' },
        },
        {
          text: 'Thêm Học Viên',
          active: true,
        },
      ],
    },
  },
  {
    path: '/manager-parent',
    name: 'manager-parent',
    props: true,
    // eslint-disable-next-line import/no-unresolved
    component: () => import('@/views/class/parent/Manage.vue'),
    meta: {
      redirectIfLoggedIn: true,
      pageTitle: 'Quản Lý Phụ Huynh',
      breadcrumb: [
        {
          text: 'Danh sách Phụ Huynh',
          active: true,
        },
      ],
    },
  },
  {
    path: '/manager-parent/edit/:id',
    name: 'manager-parent-edit',
    props: true,
    // eslint-disable-next-line import/no-unresolved
    component: () => import('@/views/class/parent/Create.vue'),
    meta: {
      redirectIfLoggedIn: true,
      pageTitle: 'Quản lý Phụ Huynh',
      breadcrumb: [
        {
          text: 'Danh sách Phụ Huynh',
          to: { name: 'manager-parent' },
        },
        {
          text: 'Sửa Phụ Huynh',
          active: true,
        },
      ],
    },
  },
  {
    path: '/manager-parent/create',
    name: 'manager-parent-create',
    // eslint-disable-next-line import/no-unresolved
    component: () => import('@/views/class/parent/Create.vue'),
    meta: {
      redirectIfLoggedIn: true,
      pageTitle: 'Quản Lý Phụ Huynh',
      breadcrumb: [
        {
          text: 'Danh Sách Phụ Huynh',
          to: { name: 'manager-parent' },
        },
        {
          text: 'Thêm Phụ Huynh',
          active: true,
        },
      ],
    },
  },
  {
    path: '/schedule-time',
    name: 'schedule-time',
    // eslint-disable-next-line import/no-unresolved
    component: () => import('@/views/class/schedule-time/Calendar.vue'),
    meta: {
      redirectIfLoggedIn: true,
      pageTitle: 'Quản Lý Thời Khóa Biểu',
      breadcrumb: [
        {
          text: 'Xem Thời Khóa Biểu',
        },
      ],
    },
  },
]
